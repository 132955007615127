<!--
 * @Author: wxb
 * @Date: 2021-06-16 14:11:40
 * @LastEditTime: 2023-05-25 10:51:59
 * @LastEditors: wangxb 18254141586@163.com
 * @Description:
 * @FilePath: \ui-djz\src\components\headerNavBlue.vue
-->
<template>
  <div class="header-containner">
    <div class="content">
      <div class="header-logo">
        <img src="../assets/logo-white.png" />
      </div>
      <div class="header-nav">
        <ul class="menubar clearfix">
          <li :class="activeIdx==1?'active':''">
            <router-link class="routerlink"
                         :to="{name:'home'}">首页</router-link>
          </li>
          <li :class="activeIdx==2 && $route.name!=='compareCert'?'active':''">
            <router-link class="routerlink"
                         :to="{name:'orgSearch'}">查机构</router-link>
          </li>
          <li :class="activeIdx==2 && $route.name==='compareCert'?'active':''">
            <router-link class="routerlink"
                         :to="{name:'compareCert'}">资质对比</router-link>
          </li>
          <li :class="activeIdx==8?'active':''">
            <router-link class="routerlink"
                         :to="{name:'achievement'}">项目业绩</router-link>
          </li>
          <li :class="activeIdx==7?'active':''">
            <router-link class="routerlink"
                         :to="{name:'registrar'}">从业人员</router-link>
          </li>
          <li :class="activeIdx==3?'active':''">
            <router-link class="routerlink"
                         :to="{name:'project'}">找项目</router-link>
          </li>
          <li :class="activeIdx==4?'active':''">
            <router-link class="routerlink"
                         :to="{name:'libary'}">搜文库</router-link>
          </li>
          <li :class="activeIdx==9?'active':''">
            <router-link class="routerlink"
                         :to="{name:'starCompany'}">明星企业</router-link>
          </li>
          <!-- <li :class="activeIdx==5?'active':''">
            <router-link class="routerlink"
                         :to="{name:'graph'}">图谱关系</router-link>
          </li> -->
          <li :class="activeIdx==6?'active':''">
            <router-link class="routerlink"
                         :to="{name:'special'}">专题</router-link>
          </li>
          <li>
            <router-link class="routerlink"
                         :to="{name:'aiAssistant'}">
              <img src="../assets/ai-assistant-white.png"
                   class="ai-assistant" />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="header-user">
        <div class="header-user-login"
             @click="handleClickLogin"
             v-show="!isLogin">登录</div>
        <div class="header-user-info"
             v-show="isLogin">
          <div class="header-user-info-vip"
               @click="handleClickVip">
            <img src="../assets/vip/Vip.png" />
            <div class="header-user-info-vip-title">
              加入会员
            </div>
          </div>
          <div class="header-user-info-head">
            <img :src="avatarImg"
                 :class="{'vip':memberType==='vipUser'}" />
            <div class="header-user-info-head-vip"
                 v-show="memberType==='vipUser'"></div>
            <div class="mod-pop-user">
              <div class="mod-pop-user-info">
                <div class="mod-pop-user-info-name">{{userInfo.name}}</div>
                <div class="mod-pop-user-info-layout"
                     title="退出"
                     @click="layout">
                  <svg class="icon"
                       aria-hidden="true">
                    <use xlink:href="#icon-tuichu"></use>
                  </svg>
                </div>
              </div>
              <div class="mod-pop-user-tools">
                <div class="mod-pop-user-tools-item"
                     @click="handleGoPage('userInfo')">
                  <div class="mod-pop-user-tools-item-icon">
                    <img src="../assets/my-info.png">
                  </div>
                  <div class="mod-pop-user-tools-item-title">个人信息</div>
                </div>
                <div class="mod-pop-user-tools-item"
                     @click="handleGoPage('myFollow')">
                  <div class="mod-pop-user-tools-item-icon">
                    <img src="../assets/my-follow.png">
                  </div>
                  <div class="mod-pop-user-tools-item-title">我的关注</div>
                </div>
                <div class="mod-pop-user-tools-item"
                     @click="handleGoPage('myCollect')">
                  <div class="mod-pop-user-tools-item-icon">
                    <img src="../assets/my-collect.png">
                  </div>
                  <div class="mod-pop-user-tools-item-title">我的收藏</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAvatar, currentUserInfo } from '@/api/user'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  name: 'headerNav',
  props: {
    activeIdx: {
      type: Number
    }
  },
  data() {
    return {
      isLogin: false,
      avatarImg: require('../assets/avatar.png'),
      userInfo: {}
    }
  },
  methods: {
    handleClickLogin() {
      localStorage.setItem('sourceurl', this.$route.fullPath)
      this.$router.push({
        name: 'login'
      })
    },
    handleClickVip() {
      this.$router.push({
        name: 'vip'
      })
    },
    layout() {
      this.$store.dispatch('logOut').then(() => {
        location.reload()
      })
    },
    handleGoPage(name) {
      const nowRouterName = this.$route.name
      if (name === nowRouterName) {
        return
      }
      this.$router.push({
        name
      })
    },
    reload() {
      getAvatar().then(res => {
        if (res.size !== 0) {
          // 构造一个blob对象来处理数据
          const blob = new Blob([res])
          this.avatarImg = URL.createObjectURL(blob)
        } else {
          if (!this.headImgUrl) {
            this.$store.dispatch('getWechatInfo').then(res => {
              if (res.wechatUserInfo) {
                this.avatarImg = res.wechatUserInfo.headImgUrl
              }
            })
          } else {
            this.avatarImg = this.headImgUrl
          }
        }
      })

      currentUserInfo().then(res => {
        this.userInfo = res.data
      }).catch(error => {
        console.error(error)
      })
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType',
      headImgUrl: 'headImgUrl'
    })
  },
  mounted() {
    if (getToken()) {
      this.isLogin = true

      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo')
      }

      getAvatar().then(res => {
        if (res.size !== 0) {
          // 构造一个blob对象来处理数据
          const blob = new Blob([res])
          this.avatarImg = URL.createObjectURL(blob)
        } else {
          if (!this.headImgUrl) {
            this.$store.dispatch('getWechatInfo').then(res => {
              if (res.wechatUserInfo) {
                this.avatarImg = res.wechatUserInfo.headImgUrl
              }
            })
          } else {
            this.avatarImg = this.headImgUrl
          }
        }
      })

      currentUserInfo().then(res => {
        this.userInfo = res.data
      }).catch(error => {
        console.error(error)
        if (error.response.status === 401) {
          this.isLogin = false
        }
      })
    } else {
      this.isLogin = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header-containner {
  width: 100%;
  min-width: 144rem;
  height: 8.6rem;
  background-image: radial-gradient(#0073bc 100%, #2982bf 0%, #0073bc 100%);

  .content {
    width: 144rem;
    height: 8.6rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .header {
    &-logo {
      width: 17.7rem;
      height: auto;
    }

    &-nav {
      flex: 1;
      position: relative;
      text-align: right;
      height: 8.6rem;
      .menubar {
        position: absolute;
        right: 0;
        li {
          float: left;
          font-size: 1.7rem;
          color: rgba(0, 0, 0, 0.65);
          line-height: 1.7rem;
          font-weight: 400;
          margin-left: 4.6rem;
          .routerlink {
            display: block;
            position: relative;
            color: #fff;
            height: 8.6rem;
            line-height: 8.6rem;
            .ai-assistant {
              width: 39px;
              height: auto;
              margin-top: 1.3rem;
            }
          }
        }

        li.active {
          .routerlink {
            border-bottom: 0.4rem solid #fff;
            font-weight: 700;
          }
        }
      }
    }

    &-user {
      text-align: left;
      padding-left: 5rem;

      &-login {
        width: 8rem;
        height: 4rem;
        border: 0.1rem solid #fff;
        border-radius: 0.2rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
        line-height: 4rem;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
      }

      &-info {
        display: flex;
        align-items: center;
        &-vip {
          text-align: center;
          padding-right: 2rem;
          cursor: pointer;
          &-title {
            width: 4.8rem;
            height: 1.7rem;
            font-size: 1.2rem;
            color: #ffffff;
            line-height: 1.7rem;
            font-weight: 500;
          }
        }
        &-head {
          width: 4.6rem;
          height: 4.6rem;
          height: 8.6rem;
          position: relative;
          padding-top: 18px;

          &-vip {
            width: 5rem;
            height: 5rem;
            position: absolute;
            top: 16px;
            left: 0px;
            background-size: cover;
            background: url("../assets/vip.png");
            background-repeat: no-repeat;
            background-position: 3.1rem 3.1rem;
          }

          img {
            width: 4.6rem;
            height: 4.6rem;
            border-radius: 50%;
            &.vip {
              border: 0.2rem solid #ffb248;
            }
          }
          &:hover {
            .mod-pop-user {
              display: block;
            }
          }

          .mod-pop-user {
            width: 39.6rem;
            height: 22.8rem;
            z-index: 10;
            position: absolute;
            top: 7.5rem;
            right: 0;
            user-select: none;
            border-radius: 0.4rem;
            display: none;
            background: #fff;
            border: 1px solid #2eaacd;
            &-info {
              background: #2eaacd;
              height: 9rem;
              border-radius: 0.4rem 0.4rem 0 0;
              color: #fff;
              display: flex;
              align-items: center;
              padding: 1rem;
              &-name {
                font-size: 1.8rem;
                flex: 1;
              }

              &-layout {
                font-size: 3.8rem;
                cursor: pointer;
              }
            }

            &-tools {
              display: flex;
              align-content: center;
              &-item {
                flex: 1;
                text-align: center;
                padding-top: 25px;
                cursor: pointer;
                &-icon {
                  img {
                    width: 65px;
                    height: auto;
                  }
                }

                &:hover {
                  .mod-pop-user-tools-item-title {
                    color: #ff5c38;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
